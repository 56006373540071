<template>
  <div class="navigation-title-section">
    <div class="navigation-title-section-content">
      {{ $route.name }}
    </div>
  </div>
  <div class="items">
    <div class="mb-2 upload">
      <div>
        <div class="search-and-form" v-if="posts">
          <div class="d-flex flex-wrap" v-if="$route.name == 'Artworks'">
            <span class="mr-2">
              <span @click="changeSort">
                <small v-if="sort == 'DEC'">A-Z</small>
                <small v-else>Z-A</small>
              </span>
            </span>
            <div class="choosenfile-imitation">
              <div class="fake-btn">
                {{ !file ? "Import/Update Artworks" : "/" + file.name }}
              </div>
              <input type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                @change="(e) => handleFileUpload(e)" />
            </div>
            <el-button :disabled="!file" type="primary" size="small" v-on:click="submitFile()">Submit</el-button>
            <div class="d-flex" style="margin-left: 5px; align-items: center">
              <small style="margin-bottom: 0">Total artworks:
                <span class="badge" style="background-color: #1f3c2a">{{
        total
      }}</span></small>
              <small style="margin-left: 10px; margin-bottom: 0">Quantity:
                <span class="badge badge-secondary" style="background-color: #1f3c2a">{{ totalQuantity }}</span></small>
            </div>
          </div>
          <div class="d-flex" v-if="$route.name == 'NFTs'">
            <add-n-f-t @onRefresh="refreshList" />
          </div>
          <div class="search-wrapper">
            <el-input v-model="search" size="small" placeholder="Type to search" @keyup.enter="serachIt" />
            <el-button type="primary" @click="serachIt" size="small">Search
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="posts && $route.name !== 'NFTs'">
      <div style="overflow-x: scroll">
        <div style="min-width: 1200px">
          <div class="row items_form_title">
            <div class="col-1">Id</div>
            <div class="col-2">Featured image</div>
            <div class="col-3">Title</div>
            <div class="col" v-if="$route.name == 'Artworks'">Artist</div>
            <div class="col" v-if="$route.name == 'Artworks'">Price</div>
            <div class="col" v-if="$route.name == 'Artworks'">Status</div>
            <div class="col" v-if="$route.name == 'Artworks'">Quantity</div>
            <div class="col" v-if="$route.name == 'Artworks'">Size</div>
            <div class="col" v-if="$route.name == 'Techniques'">
              Sub techniques
            </div>
          </div>
          <div class="row item_list" v-if="posts.length !== 0">
            <div class="row single_row_item" v-for="(item, index) in posts" :key="index">
              <div class="col-1">{{ (pageNo - 1) * 20 + index + 1 }}</div>
              <div class="col-2">
                <div class="download-image" v-if="$route.name == 'Artworks'">
                  <a v-if="item?.featured_image?.slug" :href="getPreviewDomain + item?.featured_image?.slug"
                    style="color: inherit" @click.prevent="() => downloadImg(item)">
                    <img v-lazy="getPreviewDomain + item?.featured_image.slug" width="100px" height="100px"
                      style="object-fit: contain; width: 100px; height: 100px" />
                    <div class="download-icon">
                      <font-awesome-icon :icon="['fas', 'download']" />
                    </div>
                  </a>
                  <div v-else>
                    <img src="@/assets/img/placeholder-image.jpg" width="100px" height="100px"
                      style="object-fit: contain; width: 100px; height: 100px" />
                  </div>
                </div>
                <div v-else-if="$route.name == 'Artists' || $route.name == 'Techniques'
        ">
                  <div v-if="item?.featured_image">
                    <img fit="cover" width="100px" height="100px"
                      style="object-fit: contain; width: 100px; height: 100px" v-if="item?.featured_image?.slug" v-lazy="getPreviewDomain +
        item?.featured_image?.properties?.thumbnail
        " />
                  </div>
                  <div v-else-if="item?.image">
                    <img width="100px" height="100px" style="object-fit: contain; width: 100px; height: 100px"
                      fit="cover" v-if="item?.image?.slug" v-lazy="getPreviewDomain + item?.image?.slug" />
                  </div>
                  <img v-else src="@/assets/img/placeholder-image.jpg" alt="Broken image" width="50" height="50" />
                </div>
              </div>
              <div class="col-3">
                <h5>{{ item.title || item.name }}</h5>
                <a v-if="$route.name == 'Artists'" class="single_row_item__p"
                  :href="getDomain + 'artist' + '/' + item.slug" target="_blank"
                  style="text-decoration: underline !important">Preview</a>
                <a v-if="$route.name == 'Techniques'" class="single_row_item__p"
                  :href="getDomain + 'techniques' + '/' + item.slug" target="_blank"
                  style="text-decoration: underline !important">Preview</a>
                <a v-if="$route.name == 'Artworks'" class="single_row_item__p"
                  :href="getDomain + 'artworks' + '/' + item.slug" target="_blank"
                  style="text-decoration: underline !important">Preview</a>
                <p class="single_row_item__p">
                  <router-link target="_blank" :to="{
        name: 'edit_item',
        params: { type: $route.name.toLowerCase(), id: item.id },
        query: {
          v: '2',
          mode: 'edit',
          singular:
            $route.name == 'Artworks'
              ? 'artwork'
              : $route.name == 'Artists'
                ? 'artist'
                : $route.name == 'Techniques'
                  ? 'technique'
                  : '',
        },
      }">Edit
                  </router-link>
                </p>
                <p class="single_row_item__p" style="color: #b32d2e" @click="deletePost(item.id)">
                  Move to trash
                </p>
              </div>
              <div class="col" v-if="$route.name == 'Artworks'">
                {{ item.artist.name }}
              </div>
              <div class="col" v-if="$route.name == 'Artworks'">
                ${{ ~~item.price }}
              </div>
              <div class="col" v-if="$route.name == 'Artworks'">
                {{ item.status == "1" ? "Publish" : "Draft" }}
              </div>
              <div class="col d-flex justify-content-center" v-if="$route.name == 'Artworks'">
                {{ item.quantity }}
                <div style="margin-left: 20px" class="ml-2 d-flex align-items-center flex-column">
                  <small style="font-size: 12px">Sold out?</small>
                  <input v-on:change="() => changePostOutOfStockStatus(item)" :checked="item.out_of_stock == '1'"
                    type="checkbox" id="outofstock" name="outofstock" value="Out off stock" />
                </div>
              </div>
              <div class="col" style="max-width: 400px; display: flex; flex-wrap: wrap"
                v-if="$route.name == 'Techniques'">
                <div v-for="child in item.child" :key="child">
                  <span class="badge badge-pill badge-primary" style="background-color: #1f3c2a; margin-right: 5px">#{{
        child.id + " - " + child.name }}</span>
                </div>
              </div>
              <div class="col sizes" v-if="$route.name == 'Artworks'">
                <ul v-if="sizes.length > 0">
                  <li>
                    <SizeTypes :sizes="sizes" :artwork="item" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row item_list" v-if="posts.length === 0">
            <p style="margin-top: 15px">There are no {{ $route.name }} yet.</p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- <Nft :posts="posts" /> -->
    </div>
    <div v-if="havePagination">
      <el-pagination layout="prev, pager, next" :total="totalNum" :page-count="Math.ceil(totalNum / perPage)"
        :current-page="pageNo" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
import { ElPagination, ElNotification } from "element-plus";
import AddNFT from "../nft/components/AddNFT.vue";
import SizeTypes from "./components/SizeTypes.vue";

export default {
  data() {
    return {
      posts: [],
      isPostLoaded: false,
      havePagination: false,
      totalNum: 0,
      pageNo: 1,
      file: "",
      search: "",
      perPage: 20,
      total: 0,
      totalQuantity: 0,
      sizes: [],
      sort: "DESC",
    };
  },
  components: {
    ElPagination,
    AddNFT,
    SizeTypes,
  },
  methods: {
    async changePostOutOfStockStatus(post) {
      const loader = this.$loading.show();
      try {
        await this.$api.patch(
          `${process.env.VUE_APP_SHARED_API}api/artworks/${post.id}/patch`,
          {
            out_of_stock: post.out_of_stock == "0" ? "1" : "0",
          }
        );
        ElNotification({
          title: "Success!",
          message: "Out of stock status updated successfully.",
          type: "success",
        });
        loader.hide();
      } catch (_) {
        loader.hide();
        ElNotification({
          title: "Warning!",
          message: "Unable to update out of stock status, please try again",
          type: "warning",
        });
      }
    },
    async fetchSizes() {
      try {
        const res = await this.$api.get(
          `${process.env.VUE_APP_SHARED_API}api/size-types`
        );
        this.sizes = res.data.data;
      } catch (_) { }
    },
    fetchPosts(pageNo) {
      return this.$api.get(
        `${process.env.VUE_APP_SHARED_API
        }api/${this.$route.name.toLowerCase()}?per_page=${this.perPage}${pageNo ? `&page=${pageNo}` : ``
        }&by_artistsAZ=${this.sort}${this.search && "&search=" + this.search}`
      );
    },
    openInNewTab(url) {
      window.open(`${url}`, "_blank");
    },
    pageChange(pageNo) {
      this.pageNo = pageNo;
      const loader = this.$loading.show();
      window.scrollTo(0, 0);
      this.fetchPosts(pageNo).then((res) => {
        if (res.data.data.length > 0) {
          this.posts = res.data.data;
          loader.hide();
        }
      });
    },
    deletePost(id) {
      const loader = this.$loading.show();
      this.$api
        .delete(
          `${process.env.VUE_APP_SHARED_API
          }api/${this.$route.name.toLocaleLowerCase()}/${id}`
        )
        .then((res) => {
          loader.hide();
          if (res.status === 200) {
            ElNotification({
              title: "Success!",
              message: res.data,
              type: "success",
            });
            this.posts = this.posts.filter((data) => data.id != id);
          } else {
            ElNotification({
              title: "Warning!",
              message: "Something went wrong, please try later!",
              type: "warning",
            });
          }
        })
        .catch((e) => {
          ElNotification({
            title: "Warning!",
            message: "Something went wrong, please try later!",
            type: "warning",
          });
        });
    },
    handleFileUpload(e) {
      this.file = e.target.files[0];
    },
    submitFile() {
      const loader = this.$loading.show();
      let formData = new FormData();
      formData.append("csv_file", this.file);
      this.$api
        .post(
          `${process.env.VUE_APP_SHARED_API}api/bulk-upload/artworks`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          ElNotification({
            title: "Success!",
            message: "Artwork success uploaded!",
            type: "success",
          });
          this.fetchPosts().then((res) => {
            if (res.data.data.length > 0) {
              this.posts = res.data.data;
              loader.hide();
              if (res.data.last_page > 1) {
                this.totalNum = res.data.total;
                this.havePagination = true;
              }
            }
          });
        })
        .catch((e) => {
          loader.hide();
          ElNotification({
            title: "Warning!",
            message: "Something went wrong, please try later!",
            type: "warning",
          });
        });
    },
    async serachIt(pageNo = 1) {
      if (this.search != "") {
        const loader = this.$loading.show();
        await this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API
            }api/${this.$route.name.toLowerCase()}?per_page=${this.perPage
            }&search=${this.search}&sort=DESC
          `
          )
          .then((res) => {
            loader.hide();
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            } else {
              this.totalNum = 0;
              this.havePagination = false;
            }
            if (res.data.data) {
              this.posts = res.data.data;
            } else {
              this.totalNum = 0;
              this.havePagination = false;
            }
          });
      } else {
        const loader = this.$loading.show();
        await this.$api
          .get(
            `${process.env.VUE_APP_SHARED_API
            }api/${this.$route.name.toLowerCase()}?per_page=${this.perPage
            }&search=${this.search}&sort=DESC
          `
          )
          .then((res) => {
            loader.hide();
            if (res.data.data.length > 0) {
              this.posts = res.data.data;
              loader.hide();
              if (res.data.last_page > 1) {
                this.totalNum = res.data.total;
                this.havePagination = true;
              }
            }
          });
      }
    },
    async downloadImg(item) {
      const haveImage = item?.featured_image?.slug;
      if (!haveImage) {
        return alert(
          "The desired photo could not be downloaded. Please try later."
        );
      }
      const domain = process.env.VUE_APP_SHARED_API;
      const imageURL = domain + haveImage;

      const xhr = new XMLHttpRequest();
      xhr.open("GET", imageURL, true);
      xhr.responseType = "blob";

      xhr.onload = function () {
        const blob = xhr.response;
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = Math.random() + haveImage;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(a.href);
      };
      return xhr.send();
    },
    refreshList() {
      const loader = this.$loading.show();
      this.posts = [];
      this.havePagination = false;
      this.fetchPosts(1).then((res) => {
        if (res.data.data.length > 0) {
          this.posts = res.data.data;
          loader.hide();
        }
        if (res.data.last_page > 1) {
          this.totalNum = res.data.total;
          this.havePagination = true;
        }
      });
    },
    async getQuantityData() {
      try {
        const res = await this.$api.get(
          `${process.env.VUE_APP_SHARED_API}api/artworks/total-quantity`
        );
        this.totalQuantity = res.data.total_quantity;
      } catch (_) {
        this.totalQuantity = 0;
      }
    },
    changeSort() {
      const loader = this.$loading.show();
      if (this.sort == "DESC") {
        this.sort = 'ADC';
      } else {
        this.sort = 'DESC';
      }
      this.fetchPosts().then((res) => {
        if (res.data.data.length > 0) {
          this.total = res.data.total;
          this.posts = res.data.data;
          if (res.data.last_page > 1) {
            this.totalNum = res.data.total;
            this.havePagination = true;
          }
        }
        loader.hide();
      }).catch(() => {
        loader.hide();
      });

    },
  },
  mounted() {
    const loader = this.$loading.show();
    try {
      this.fetchSizes();
      this.getQuantityData();
      this.fetchPosts().then((res) => {
        if (res.data.data.length > 0) {
          this.total = res.data.total;
          this.posts = res.data.data;
          if (res.data.last_page > 1) {
            this.totalNum = res.data.total;
            this.havePagination = true;
          }
        }
      });
      loader.hide();
    } catch (e) {
      loader.hide();
    }
  },
  watch: {
    $route(to, from) {
      // let loader;
      // if (this.$route.name) loader = this.$loading.show();
      this.posts = [];
      this.isPostLoaded = false;
      this.havePagination = false;
      this.totalNum = 0;
      this.pageNo = 1;
      this.search = "";
      this.file = "";
      if (
        this.$route.name.toLocaleLowerCase() === "artworks" ||
        this.$route.name.toLocaleLowerCase() === "artists" ||
        this.$route.name.toLocaleLowerCase() === "techniques"
      ) {
        this.fetchPosts().then((res) => {
          // if (this.$route.name) loader.hide();
          if (res.data.data.length > 0) {
            this.posts = res.data.data;
            if (res.data.last_page > 1) {
              this.totalNum = res.data.total;
              this.havePagination = true;
            }
          }
        });
      }
    },
  },
  computed: {
    getDomain() {
      return process.env.VUE_APP_FRONT_PREVIEW;
    },
    getPreviewDomain() {
      return process.env.VUE_APP_SHARED_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.sizes {
  max-height: 100px;
  overflow-y: scroll;
}

.sizes ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sizes li {
  display: flex;
  font-size: 13px;
  margin-bottom: 5px;
}

.sizes input {
  margin-right: 5px;
}

@media not all and (hover: none) {
  .download-icon {
    visibility: visible;
  }
}

.download-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.download-icon {
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  background-color: #1f3c2a;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.download-image:hover .download-icon {
  visibility: visible;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 7px;
  color: white;
}

.languages_switcher {
  .languages_switcher__p {
    display: inline-block;
    margin: 0 5px;

    a {
      display: inline-block;
      background-color: transparent;
      color: #338973;
      border: 1px solid #338973;
      border-bottom: 0;
      text-align: center;
      padding-top: 5px;
      width: 50px;
      font-size: 18px;
      text-decoration: none;
      text-transform: uppercase;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
}

.new_menu_button_sec {
  position: absolute;
  right: 15px;

  button {
    background-color: white;
    text-transform: uppercase;
    color: #338973;
  }
}

.items {
  padding-right: 30px;
  padding-left: 30px;

  .items_form_title {
    border-radius: 0px;
    background-color: #1f3c2a;
    color: white;
    padding: 5px 25px;
    text-align: left;

    .items_form_title__columns {
      padding: 12px;
      padding-left: 0;
    }

    p {
      margin-bottom: 0;
    }
  }

  .item_list {
    padding: 5px 25px;
    border: 2px solid #1f3c2a;
    border-top: none;
    margin-top: -15px;
    padding-top: 25px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .single_row_item {
      border-bottom: 1px solid #ccc;
      align-content: center;
      align-items: center;
      padding: 15px 0;
      margin: 0;

      h5 {
        font-weight: normal;
        line-height: 30px;
      }

      .single_row_item__p {
        color: #338973;
        display: inline-block;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 0;
        text-decoration: none !important;

        a {
          color: #338973;
          text-decoration: none !important;
        }
      }

      .add_or_edit_item_language {
        display: flex;

        div {
          flex: 0 0 10%;
        }
      }

      .single_row_item__p:after {
        content: "|";
        margin: 0 5px;
      }

      .single_row_item__p:last-child:after {
        content: "";
      }

      .language_icon {
        font-size: 14px;
      }

      .language_icon.language_icon__add {
        color: #338973;
      }

      .language_icon.language_icon__edit {
        color: #9c27b0;
      }
    }

    .single_row_item:last-child {
      border-bottom: none;
    }

    .single_row_item>* {
      padding: 0;
      padding-left: 5px;
    }
  }
}

.dialog_category_p {
  font-size: 18px;
}

.upload {
  margin-left: auto;
  margin-right: 0;
  display: block;
}

.search-and-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-wrapper {
  display: flex;
  width: 25%;
}

.d-flex {
  display: flex;
}

.choosenfile-imitation {
  position: relative;
}

.fake-btn {
  color: #fff;
  white-space: nowrap;
  text-align: center;
  background-color: #1f3c2a;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-right: 1px solid white;
}

.choosenfile-imitation input {
  opacity: 0;
  cursor: wait;
  z-index: 9;
}

@media (max-width: 550px) {
  .search-and-form {
    flex-wrap: wrap;
  }

  .search-and-form .d-flex,
  .search-and-form .choosenfile-imitation,
  .search-and-form .search-wrapper {
    width: 100%;
  }

  .search-wrapper {
    margin-top: 10px;
  }
}

.mr-2 {
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mr-2 small {
  font-weight: bold;
}
</style>
