<template>
  <div class="row">
    <div v-if="propsItem.type === 'pages'">
      Original:
      <a
        v-if="propsItem.data"
        :href="slugParser(propsItem.data.value)"
        target="_blank"
        >{{ propsItem.data.label }}</a
      >
    </div>
    <div class="col-12 col-sm-3 d-flex">
      <small>Name <span style="color: red">*</span></small>
      <el-input
        v-model="propsItem.name"
        type="text"
        required
        placeholder="Name"
      ></el-input>
    </div>
    <div class="col-12 col-sm-3 d-flex">
      <small>Type <span style="color: red">*</span></small>
      <el-select v-model="propsItem.type" placeholder="Select">
        <el-option-group
          v-for="group in options"
          :key="group.id"
          :label="group.type"
          :value="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.label"
            :label="item.value"
            :value="item.label"
          />
        </el-option-group>
      </el-select>
    </div>
    <div v-if="propsItem.type" class="col-12 col-sm-3 d-flex">
      <small>Link <span style="color: red">*</span></small>
      <div v-if="propsItem.type === 'external'">
        <el-input
          v-model="propsItem.externalLink"
          type="text"
          required
          placeholder="Link"
        ></el-input>
      </div>
      <div v-else>
        <el-select
          v-model="propsItem.data"
          filterable
          remote
          placeholder="Please enter a keyword"
          :remote-method="(query) => remoteMethod(query, propsItem.type)"
          :loading="loading"
          value-key="id"
        >
          <el-option
            v-for="propsItem in serverData"
            :key="propsItem.value"
            :label="propsItem.label"
            :value="propsItem"
          />
        </el-select>
      </div>
    </div>
    <div v-if="propsItem.type" class="col-12 col-sm-3 d-flex">
      <small>Target?</small>
      <el-checkbox
        v-model="propsItem.blankTarget"
        label="Open in new tab?"
        size="small"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    propsData: Object,
    parentId: Number,
  },
  data() {
    return {
      propsItem: {
        name: "",
        type: "",
        data: {},
        blankTarget: false,
        externalLink: "",
      },
      loading: false,
      query: "",
      serverData: [],
      options: [
        {
          id: 0,
          type: "Pages",
          label: "pages",
          options: [
            {
              value: "Pages",
              label: "pages",
            },
          ],
        },
        {
          id: 4,
          type: "Category + Tags (filter)",
          label: "filter",
          options: [
            {
              value: "Collectors Items",
              label: "collector-items",
            },
            {
              value: "Colors",
              label: "colors",
            },
            {
              value: "Types",
              label: "types",
            },
            {
              value: "Sizes",
              label: "sizes",
            },
            {
              value: "Suits",
              label: "suits",
            },
            {
              value: "Tags",
              label: "tags",
            },
          ],
        },
        {
          id: 5,
          type: "External",
          label: "external",
          options: [
            {
              value: "External",
              label: "external",
            },
          ],
        },
        {
          id: 6,
          type: "Artworks",
          label: "artworks",
          options: [
            {
              value: "Artworks",
              label: "artworks",
            },
          ],
        },
        {
          id: 7,
          type: "Artists",
          label: "artists",
          options: [
            {
              value: "Artists",
              label: "artists",
            },
          ],
        },
        {
          id: 8,
          type: "Techniques",
          label: "techniques",
          options: [
            {
              value: "Techniques",
              label: "techniques",
            },
          ],
        },
      ],
    };
  },
  methods: {
    remoteMethod: function (query, type) {
      if (query) {
        this.loading = true;
        this.serverData = [];
        let fetchEndpoint = type;
        if (fetchEndpoint == "pages") {
          fetchEndpoint = "page-translations";
        }
        this.$api
          .get(
            `${
              process.env.VUE_APP_SHARED_API
            }api/${fetchEndpoint}?search=${query.toLowerCase()}`
          )
          .then((res) => {
            if (res.data.data) {
              res.data.data.map((item) => {
                this.serverData.push({
                  id: item.id,
                  label: item.name || item.title,
                  value: item,
                });
              });
              this.loading = false;
            } else {
              this.loading = false;
            }
          });
      } else {
        this.serverData = [];
      }
    },
    slugParser(item) {
      if (item?.page?.location == "frontpage") {
        return process.env.VUE_APP_FRONT_PREVIEW;
      } else if (item?.page?.location == "archive-techniques") {
        return process.env.VUE_APP_FRONT_PREVIEW + "techniques";
      } else if (item?.page?.location == "archive-artists") {
        return process.env.VUE_APP_FRONT_PREVIEW + "artist";
      } else if (item?.slug) {
        if (item?.quantity) {
          return process.env.VUE_APP_FRONT_PREVIEW + "artworks/" + item.slug;
        } else if (item?.biography) {
          return process.env.VUE_APP_FRONT_PREVIEW + "artist/" + item.slug;
        } else if (item?.principles) {
          return process.env.VUE_APP_FRONT_PREVIEW + "techniques/" + item.slug;
        } else {
          return process.env.VUE_APP_FRONT_PREVIEW + "pages/" + item.slug;
        }
      } else {
        return "#";
      }
    },
    addNewRow() {
      this.megaMenu.push({
        name: "",
        type: "",
        data: {},
        blankTarget: false,
        externalLink: "",
      });
    },
    watch: {
      "menuItemData.type"(oldV, newV) {
        if (newV) {
          this.menuItemData.data = {};
          this.menuItemData.externalLink = "";
          this.data = [];
        }
      },
    },
  },
  mounted() {
    this.propsItem = { ...this.propsData };
    this.serverData = this.propsData?.data ? [this.propsData.data] : [];
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "COLLECT_GROUP_MENU_DATA") {
        this.$store.dispatch("addGroupMediaData", {
          parentId: this.parentId,
          menuData: this.propsItem,
        });
      }
    });
  },
  beforeUnmount() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
</style>
